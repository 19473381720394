p:last-child, dl:last-child, dd:last-child, ul:last-child, ol:last-child, address:last-child {margin-bottom: 0;}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    font-family: var(--my-headings-font-family);
    font-weight: var(--my-headings-font-weight);
}

img,
video {
    max-width: 100%;
    pointer-events: none;
}